<template>
  <div
    v-if="!isLoading"
  >
    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="400px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('lbl.cancelated')}!!!` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('msg.deleteBookingItem', { n: item.code }) }}<br />
          {{ $t('msg.deleteUndo') }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.no') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template v-if="reservasPaginate.length > 0">
      <v-row>
        <v-col
          cols="12"
        >
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    width="15px"
                    class="text-uppercase"
                  >
                    {{ $t('lbl.code') }}
                  </th>
                  <th class="text-uppercase">
                    {{ $t('lbl.client') }}
                  </th>
                  <th class="text-uppercase">
                    {{ $t('lbl.product') }}
                  </th>
                  <th
                    width="15px"
                    class="text-uppercase"
                  >
                    {{ $t('lbl.service') }}
                  </th>
                  <th
                    width="15px"
                    class="text-uppercase"
                  >
                    {{ $t('lbl.monto') }}
                  </th>
                  <th class="text-uppercase">
                    {{ $t('lbl.vendedor') }}
                  </th>
                  <!--<th class="text-uppercase">
            {{ $t('lbl.compra') }}
          </th>-->
                  <th
                    width="15px"
                    class="text-uppercase"
                  >
                    {{ $t('lbl.state') }}
                  </th>
                  <th
                    v-if="
                      permisos.includes('reservas:list') ||
                        permisos.includes('reservas:edit') ||
                        permisos.includes('reservas:destroy') ||
                        permisos.includes('*')
                    "
                    width="15px"
                    class="text-uppercase"
                  >
                    {{ $t('lbl.actions') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(reserva, index) in reservasShowPaginate"
                  :key="index"
                >
                  <td>
                    <span
                      class="d-inline-block text-caption"
                      :style="`color: ${$vuetify.theme.themes.light.primary}`"
                    >
                      {{ reserva.code }}
                    </span>
                  </td>
                  <td>
                    <span v-if="reserva.cliente">
                      <template v-if="reserva.cliente.name">
                        {{ reserva.cliente.name }}
                        {{ reserva.cliente.apellidos }}<br />
                        <a
                          v-if="reserva.cliente.email"
                          :href="`mailito:${reserva.cliente.email}`"
                        >
                          {{ reserva.cliente.email }}
                        </a>
                        <a
                          v-else-if="reserva.cliente.emails.length > 0"
                          :href="`mailito:${reserva.cliente.emails[0]}`"
                        >
                          {{ reserva.cliente.emails[0] }}
                        </a>
                        <span v-if="reserva.cliente.telefono"> <br />{{ reserva.cliente.telefono }} </span>
                      </template>
                    </span>
                    <!--<span
                  v-if="reserva.cliente.length > 0"
                  class="d-inline-block text-caption"
                >
                  <span v-if="reserva.cliente[0].email">
                    {{ reserva.cliente[0].email }}
                  </span>
                </span> -->
                  </td>
                  <td>
                    <fragment v-if="reserva.entities">
                      <span v-if="reserva.entities.length > 1">
                        <v-tooltip
                          top
                          color="primary"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon>{{ icons.mdiPackage }}</v-icon> {{ $t('lbl.paquet') }}
                            </span>
                          </template>
                          <span>
                            <span
                              v-for="(entity, ind) in reserva.entities"
                              :key="ind"
                            >
                              <fragment v-if="entity === 'flights'">
                                <v-icon style="color: #FFF;">{{ icons.mdiAirplaneTakeoff }}</v-icon> {{ $t('lbl.flight')
                                }}<br />
                              </fragment>
                              <fragment v-if="entity === 'cars'">
                                <v-icon style="color: #FFF;">{{ icons.mdiCar }}</v-icon> {{ $t('lbl.car') }} <br />
                              </fragment>
                              <fragment v-if="entity === 'hotels'">
                                <v-icon style="color: #FFF;">{{ icons.mdiBed }}</v-icon> {{ $t('lbl.hotel') }} <br />
                              </fragment>
                            </span>
                          </span>
                        </v-tooltip>
                      </span>
                      <span v-else>
                        <fragment v-if="reserva.entities.includes('flights')">
                          <v-icon>{{ icons.mdiAirplaneTakeoff }}</v-icon> {{ $t('lbl.flight') }}
                        </fragment>
                        <fragment v-else-if="reserva.entities.includes('cars')">
                          <v-icon>{{ icons.mdiCar }}</v-icon> {{ $t('lbl.car') }}
                        </fragment>
                        <fragment v-else-if="reserva.entities.includes('hotels')">
                          <v-tooltip
                            top
                            color="primary"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>{{ icons.mdiBed }}</v-icon> {{ $t('lbl.hotel') }}
                              </span>
                            </template>
                            <span>
                              <span
                                v-for="(cart, indC) in reserva.car_shop"
                                :key="indC"
                              >
                                {{ $t('lbl.hotel') }}: {{ cart.title }}<br />
                                <span
                                  v-for="(itemCode, indCode) in cart.codes"
                                  :key="indCode"
                                >
                                  Ref: {{ itemCode }}<br />
                                </span>
                                <hr v-if="indC < reserva.car_shop.length - 1" />
                              </span>
                            </span>
                          </v-tooltip>
                        </fragment>
                      </span>
                    </fragment>
                  </td>
                  <td>
                    <span v-if="reserva.date_servicio.length > 0">
                      {{ reserva.date_servicio[0] | moment('D/MM/Y') }}
                    </span>
                  </td>
                  <td>
                    <span
                      :style="`color: ${$vuetify.theme.themes.light.primary}`"
                    >
                      <v-tooltip
                        top
                        color="primary"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ reserva.pricePayOnline | currency(reserva.symbolPayOnline) }}
                          </span>
                        </template>
                        <span>
                          {{ $t('lbl.venta') }}: {{ reserva.pricePayOnline | currency(reserva.symbolPayOnline) }} ({{ reserva.codePayOnline }})
                          <br />
                          {{ $t('lbl.neto') }}: {{ reserva.priceNeto | currency(reserva.symbolNeto) }} ({{ reserva.codeNeto }})
                          <br />
                          Utilidad: {{ reserva.priceUtilidad | currency('$') }}
                          <br />
                          Comisión: {{ reserva.priceComision | currency('$') }}
                        </span>
                      </v-tooltip>
                    </span>
                  </td>
                  <!--<td>
                {{ $t('lbl.venta') }}: <br />
                {{ $t('lbl.neto') }}: <br />
                Utilidad: <br />
                Comisión: <br />
              </td>
              <td style="text-align: right;">
                <span
                  :style="`color: ${$vuetify.theme.themes.light.primary}`"
                >
                  <v-tooltip
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ reserva.pricePayOnline | currency(reserva.symbolPayOnline) }}
                      </span>
                    </template>
                    <span>
                      {{ reserva.codePayOnline }}
                    </span>
                  </v-tooltip>
                </span> <br />
                <span
                  :style="`color: ${$vuetify.theme.themes.light.primary}`"
                >
                  <v-tooltip
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ reserva.priceNeto | currency(reserva.symbolNeto) }}
                      </span>
                    </template>
                    <span>
                      {{ reserva.codeNeto }}
                    </span>
                  </v-tooltip>
                </span> <br />
                <span
                  :style="`color: ${$vuetify.theme.themes.light.primary}`"
                >
                  {{ reserva.priceUtilidad | currency('$') }}
                </span> <br />
                <span
                  :style="`color: ${$vuetify.theme.themes.light.primary}`"
                >
                  {{ reserva.priceComision | currency('$') }}
                </span>
              </td>-->
                  <td width="20%">
                    <span class="d-inline-block text-caption">{{ reserva.afiliado.name }}</span><br />
                    {{
                      reserva.afiliado.company !== null
                        ? reserva.afiliado.company
                        : reserva.afiliado.nivel === 0
                          ? 'Propietario'
                          : ''
                    }}
                  </td>
                  <!--<td>{{ reserva.date_compra | moment('D/MM/Y') }}</td>-->
                  <td>
                    <v-chip
                      v-if="reserva.state_reserva"
                      :color="reserva.color_state"
                      text-color="white"
                    >
                      {{ reserva.state_reserva.name }}
                    </v-chip>
                  </td>
                  <td
                    v-if="permisos.includes('reservas:edit') || permisos.includes('reservas:list') || permisos.includes('*')"
                    width="10%"
                  >
                    <v-menu
                      v-if="
                        (permisos.includes('reservas:edit') || permisos.includes('reservas:list') || permisos.includes('*'))
                          && reserva.code
                      "
                      bottom
                      left
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          v-if="permisos.includes('reservas:list')"
                          link
                          @click="showItem(reserva)"
                        >
                          <v-list-item-title>
                            <v-btn icon>
                              <v-icon>{{ icons.mdiEye }}</v-icon>
                            </v-btn>
                            <span>{{ $t('btn.see') }}</span>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="
                            (permisos.includes('reservas:edit') || permisos.includes('*')) &&
                              reserva.state_reserva.slug !== 'cancelada' &&
                              (reserva.afiliado.nivel === user.nivel || user.nivel <= 0)
                          "
                          link
                          @click="editItem(reserva)"
                        >
                          <v-list-item-title>
                            <v-btn icon>
                              <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                            </v-btn>
                            <span>{{ $t('btn.edit') }}</span>
                          </v-list-item-title>
                        </v-list-item>
                        <!--<v-list-item
                      v-if="
                        (permisos.includes('reservas:destroy') || permisos.includes('*')) &&
                          reserva.state_reserva.slug !== 'cancelada' &&
                          (reserva.afiliado.nivel === user.nivel || user.nivel <= 0)
                      "
                      link
                    >
                      <v-list-item-title @click="deleteItem(reserva)">
                        <v-btn icon>
                          <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                        </v-btn>
                        <span>{{ $t('btn.cancele') }}</span>
                      </v-list-item-title>
                    </v-list-item>-->
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row v-if="reservasShowPaginate.length > 0">
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="currentPagination"
            :length="totalPagination"
            total-visible="5"
          ></v-pagination>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-row class="mt-5">
        <v-col
          cols="12"
          md="4"
          offset-md="4"
        >
          <v-alert
            dense
            outlined
            type="error"
          >
            {{ $t('msg.noReservas') }}
            {{ $t('msg.changeFilters') }}
          </v-alert>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiAirplaneTakeoff,
  mdiCar,
  mdiBed,
  mdiClose,
  mdiEye,
  mdiEraserVariant,
  mdiPackage,
  mdiDotsVertical,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    states: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    tipos_product: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    names: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    codes: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiAirplaneTakeoff,
        mdiCar,
        mdiBed,
        mdiClose,
        mdiEye,
        mdiEraserVariant,
        mdiPackage,
        mdiDotsVertical,
      },
      isDialogVisibleDelete: false,
      item: {},
      itemId: null,
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      currentPagination: 1,
      itemsPerPage: 10,
    }
  },
  computed: {
    ...mapState({
      reservasPaginate: state => state.app.reservasPaginate,
    }),
    totalPagination() {
      const result = Math.ceil(this.reservasPaginate.length / this.itemsPerPage)

      return result
    },
    reservasShowPaginate() {
      const arr = []

      const first = (this.currentPagination - 1) * this.itemsPerPage
      let last = this.currentPagination * this.itemsPerPage
      if (this.currentPagination === this.totalPagination) {
        last = this.reservasPaginate.length
      }
      // eslint-disable-next-line no-plusplus
      for (let index = first; index < last; index++) {
        arr.push(this.reservasPaginate[index])
      }

      return arr
    },
  },
  created() {
    this.isLoading = false
  },
  methods: {
    ...mapMutations(['setPosCotizador']),
    showItem(iten) {
      localStorage.setItem('reservations-show', true)
      localStorage.setItem('reservations-id', iten.id)
      this.$router.push({ name: 'reservations-update' })
    },
    editItem(iten) {
      localStorage.removeItem('reservations-show')
      localStorage.setItem('reservations-id', iten.id)
      this.$router.push({ name: 'reservations-update' })
    },

    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      const json = {
        reserve_id: this.itemId,
      }
      this.loading = true
      this.axios
        .post('reservations/cancele', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === true) {
            window.location.reload()
          } else {
            this.$toast.error('No ha sido posible reembolsar su pago')
          }
        })
        .finally(() => this.reseat())
    },

    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
